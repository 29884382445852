import { CircularProgress, Typography } from '@mui/material';
import { AlertTitle } from '@mui/material';
import axios, { AxiosError } from 'axios';
import { PageProps } from 'gatsby';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { GITHUB_AUTH_URL, GITHUB_LOGIN_STATE_KEY, BROADCAST_GITHUB_LOGIN } from '../constants';
import { BroadcastChannel } from 'broadcast-channel';
import { ContainerStyle, AlertStyle } from '../styles';

interface StatusState {
  user?: string,
  error_msg?: string,
}

interface ServerResponse {
  data: ServerData
  status: number
}

interface ServerData {
  error?: string,
  token: string,
  login?: string,
}

const SignInCompletePage: React.FC<PageProps> = ({ location }) => {

  const [state_status, set_state_status] = useState<StatusState>({});
  const get_token = async () => {
    const params = queryString.parse(location.search);
    const session_state = sessionStorage.getItem(GITHUB_LOGIN_STATE_KEY);
    if (params.state !== session_state) {
      set_state_status({ error_msg: `state: ${params.state as string} does not match: ${session_state as string}` })
      return
    }
    if (!params.code) {
      set_state_status({ error_msg: `missing code parameter from GitHub redirect response` })
      return
    }
    try {
      let res: ServerResponse = await axios.get(GITHUB_AUTH_URL + `/${params.code as string}`)
      console.log('axios res status: ', res.status)
      if (res.status !== 200) {
        set_state_status({
          error_msg: "Bad token status returned. HTTP status: " + String(res.status)
        })
        return
      }
      if (res.data.error) {
        set_state_status({
          error_msg: "Token error: " + res.data.error
        })
        return
      }
      const token = res.data.token;
      // try to get user
      res = await axios.get('https://api.github.com/user', { headers: { 'Authorization': `token ${token}` } })
      if (res.status !== 200) {
        set_state_status({
          error_msg: "Bad user status returned. HTTP status: " + String(res.status)
        })
        return
      }
      const user = res.data.login;
      if (!user) {
        console.log('Failed getting user even though status is valid. Data: ', res.data)
        set_state_status({
          error_msg: "Failed getting user data from GitHub api"
        })
        return
      }
      console.log('Success sign in for user: ', user)
      const bc = new BroadcastChannel(BROADCAST_GITHUB_LOGIN)
      void bc.postMessage({
        user,
        token,
      })
      set_state_status({
        user
      })
      // close window after 2 seconds
      setTimeout(() => {
        window.close()
      }, 2 * 1000)
    } catch (e) {
      const err = e as Error | AxiosError;
      let msg: string;
      if (axios.isAxiosError(err) && err.response) {
        msg = `${JSON.stringify(err.response.data)} (${String(err.response.status)})`
      }
      else {
        msg = err.message
      }
      set_state_status({
        error_msg: msg,
      })
    }
  }

  useEffect(() => {
    if (!state_status.error_msg && !state_status.user) {
      void get_token()
    }
  });

  return (
    <ContainerStyle>
      {(!state_status.user && !state_status.error_msg) ?
        <CircularProgress /> :
        (state_status.error_msg ?
          <AlertStyle severity='error'>
            <AlertTitle>Error</AlertTitle>
            {`Failed signing in. Please close this window and try again. Error: ${state_status.error_msg}`}
          </AlertStyle> :
          <React.Fragment>
            <Typography variant="h3" gutterBottom>
              {`Welcome ${state_status.user as string}!`}
          </Typography>
            <Typography variant="body1" gutterBottom>
              Sign In has completed. You may close this window.
          </Typography>
          </React.Fragment>

        )
      }
    </ContainerStyle>
  );
}

export default SignInCompletePage;

